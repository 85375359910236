import { HomePage } from './pages/HomePage';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"


function App() {
  return (
    <div className="App">
      <HomePage />
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
